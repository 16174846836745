import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SiteIndex = ({ data, location }) => {

  return (
    <Layout>
      <SEO title="Agencia desarrollo web Drupal | asdloop" description="Somos una agencia de desarrollo web especializada en Drupal, hacemos desarrollos a medida, migraciones a Drupal 8 y servicios de consultoría especializada."  />
      <section className="jumbotron bg-transparent pb-5" id="header">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col">
              <h1
                className="font-weight-bold display-4 pl-0 p-1 col-lg-8 offset-lg-2 col-sm-12">Hola,
                somos <span class="text-primary">asdloop</span>. Agencia de desarrollo web especializada en
                Drupal.</h1>
              <p className="lead mb-4 col-md-8 offset-md-2 col-sm-12">Realizamos desarrollos Drupal a medida y migraciones a Drupal 8, entre otros <Link to="/servicios-drupal">servicios</Link>.</p>
              <div className="btn-hero col-sm-12">
                <a className="btn btn-lg btn-outline-primary" href="/contacta">¿Hablamos?
                  →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pt-0 pb-5 bg-transparent overflow-hidden" id="features">
        <div
          className="row project-preview-left align-items-center pb-md-5 bg-light p-3 p-md-5">
          <div className="col-md-4 m-md-auto order-md-5">
            <img className="img-fluid"
                 src={"/images/helse-drupal-2.png"} alt="previsualizacion web Helse desarrollada en Drupal"/>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <h2>
              <strong>Helse</strong>
            </h2>
            <p className="lead">Desarrollo de sitio web y plataforma de gestión
              de pacientes y ejercicios en Drupal 8.</p>
            <Link className="btn mr-4 btn-lg btn-outline-primary"
               to="/proyectos/helse-drupal8">Ver Detalles →</Link>
          </div>
        </div>
        <div className="row project-preview-right align-items-center pt-5 pb-md-5 bg-transparent p-3 p-md-5">
          <div className="col-md-4">
            <img className="img-fluid" src="/images/dimarzio-drupal-2.png" alt="vista previa de web Dimarzio desarrollada en Drupal" />
          </div>
          <div className="col-md-5 m-md-auto mt-5 mt-md-0">
            <h2>
              <strong>Di Marzio</strong>
            </h2>
            <p className="lead">Desarrollo web para cliente lider en EEUU fabricante de pastillas y hardware para guitarras.</p>
            <a className="btn mr-4 btn-lg btn-outline-primary" href="/proyectos/dimarzio-drupal-8">Ver Detalles →</a>
          </div>
        </div>
        <div
          className="row project-preview-left align-items-center pt-5 pb-md-5 bg-light p-3 p-md-5">
          <div className="col-md-6 m-md-auto order-md-5">
            <img className="img-fluid pl-md-5"
                 src={"/images/eaglemoss-drupal-2.png"} alt="miniatura web Eaglemoss desarrollada en Drupal"
                 width="500"/>
          </div>
          <div className="col-md-5 mt-5 mt-md-0">
            <h2>
              <strong>Eaglemoss</strong>
            </h2>
            <p className="lead">Desarrollo de e-commerce multi-tienda / multi-pais líder en el
              sector de figuras coleccionables.</p>
            <a className="btn mr-4 btn-lg btn-outline-primary"
               href="/proyectos/eaglemoss-drupal">Ver Detalles →</a>
          </div>
        </div>
      </section>
      <section className="bg-transparent pb-3" id="clients">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-2 col-6">
              <img className="img-grayscale mx-auto d-block my-4"
                   src={"/images/gobaragon-logo.png"} alt="logo Gobierno de Aragon"
                   height="40"/>
            </div>
            <div className="col-md-2 col-6">
              <img className="img-grayscale mx-auto d-block my-4"
                   src={"/images/unizar-logo.png"} alt="logo Universidad de Zaragoza"
                   height="40"/>
            </div>
            <div className="col-md-2 col-6 text-left">
              <img className="img-grayscale mx-auto d-block my-4"
                   src={"/images/orange-logo.png"} alt="logo Orange"
                   height="40"/>
            </div>
            <div className="col-md-2 col-6">
              <img className="img-grayscale mx-auto d-block my-4"
                   src={"/images/avatar-logo.png"}  alt="logo Avatar NY"
                   height="40"/>
            </div>
            <div className="col-md-2 col-6">
              <img className="img-grayscale mx-auto d-block my-4"
                   src={"/images/fontsmith-logo.png"}  alt="logo Fontsmith"
                   height="40"/>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 pt-5 line-center bg-transparent container-fluid"
               id="unirte-ayuda">
        <div className="row border border-dark border-bottom-0 ">
          <div className="col col-md-5 p-5 offset-md-1 ">
            <h4>
              <strong>¿Quieres migrar tu sitio a Drupal 8?</strong>
            </h4>
            <p className="lead">Si tienes un sitio web en una versión antigua de Drupal, es el momento de actualizarlo.</p>
            <a className="btn mr-4 btn-lg btn-outline-primary mt-3"
               href="/servicios-drupal">Ver servicios →</a>
          </div>
          <div className="col col-md-6  borders-left p-5">
            <h4>
              <strong>¿Podemos ayudarte con tu proyecto?</strong>
            </h4>
            <p className="lead">Si tienes un proyecto web y
              necesitas ayuda, estaremos encantados de escucharte.</p>
            <a className="btn mr-4 btn-lg btn-outline-primary mt-3"
               href="/contacta">Contactar →</a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
